const APP = 'https://test.retailtune.com/rtapp/store/';
// const APP = 'https://app.retailtune.com/rtapp/store/';

// pages
export const SERVICES_URL = `${APP}?page=services`;
export const WEEKLY_OPENINGS_URL = `${APP}?page=openings`;
export const SPECIAL_OPENINGS_URL = `${APP}?page=special-openings`;

// data
export const STORE_INFO_URL = `${APP}info`;
export const SERVICES_DATA_URL = `${APP}services`;
export const WEEKLY_OPENINGS_DATA_URL = `${APP}otimes`;
export const SPECIAL_OPENINGS_DATA_URL = `${APP}special-otimes`;
