import React, { useState, useEffect } from 'react'
import moment from 'moment';

import { useStoreInfo } from '../../stores/storesInfo';
import { useMetrics } from '../../stores/metrics';
import { useStore } from '../../stores/openings';
import { updateSpecialOpeningsData, updateWeeklyOpeningsData } from '../../actions/openings';

import infoLogo from "../../images/info.svg";



const h12 = "hh:mm A", h24 = "HH:mm";

export function formatH24(t) {
    let time = moment(t, [h12]).format(h24);
    return time === 'Invalid date' ? '' : time;
}

export function formatH12(t) {
    return moment(t, [h24]).format(h12);
}




export const Opening = ({ jsonKey, data, optionalPropsOnDataChange = () => ({}), updateStoreFunction }) => {

    const { dispatch } = useStore();
    const [todayInfo, setTodayInfo] = useState(data[jsonKey]);

    const handlePropertyChange = (event, property) => {
        let value = event.target.value;
        console.log(`Changing property "${property}" with value: ${value}`);
        const newData = {
            ...data,
            [jsonKey]: {
                ...data[jsonKey],
                [property]: !value ? "" : formatH12(value),
                ...optionalPropsOnDataChange()
            }
        }
        dispatch(updateStoreFunction(newData));
        setTodayInfo({ ...todayInfo, [property]: value });
    }

    const handleReset = (e) => {
        e.preventDefault();
        const updatedValues = {
            ...todayInfo,
            closing1: '',
            closing2: '',
            opening1: '',
            opening2: '',
            ...optionalPropsOnDataChange()
        }
        const newData = {
            ...data,
            [jsonKey]: { ...updatedValues }
        }
        dispatch(updateStoreFunction(newData));
        setTodayInfo(updatedValues);
    }

    const handleClose = (e) => {
        e.preventDefault();
        const updatedValues = {
            ...todayInfo,
            closed: !todayInfo.closed,
            closing1: '',
            closing2: '',
            opening1: '',
            opening2: '',
            ...optionalPropsOnDataChange()
        }
        const newData = {
            ...data,
            [jsonKey]: { ...updatedValues }
        }
        dispatch(updateStoreFunction(newData));
        setTodayInfo(updatedValues);
    }

    return (
        <div className='tab-content'>
            <div className='row'>
                <div className='info'>
                    <span>
                        <img src={infoLogo} alt='' />
                    </span>
                    <p>Se lo store fa orario continuato inserisci solo i primi due orari.</p>
                </div>
                <div className='cont-input'>
                    <label>Orario di apertura</label>
                    <input
                        type='time'
                        className='input-bianco'
                        disabled={todayInfo.closed}
                        value={formatH24(todayInfo.opening1)}
                        required={!todayInfo.closed}
                        onChange={(e) => handlePropertyChange(e, 'opening1')}
                    />
                </div>
                <div className='cont-input mBottom'>
                    <label>Orario di chiusura</label>
                    <input
                        type='time'
                        className='input-bianco'
                        disabled={todayInfo.closed}
                        value={formatH24(todayInfo.closing1)}
                        required={!todayInfo.closed}
                        min={formatH24(todayInfo.opening1)}
                        onChange={(e) => handlePropertyChange(e, 'closing1')}
                    />
                </div>
                <div className='cont-input'>
                    <label>Orario di apertura</label>
                    <input
                        id={`${jsonKey}__test-input`}
                        type='time'
                        className='input-bianco'
                        disabled={todayInfo.closed}
                        value={formatH24(todayInfo.opening2)}
                        min={formatH24(todayInfo.closing1)}
                        required={false}
                        onChange={(e) => handlePropertyChange(e, 'opening2')}
                    />
                </div>
                <div className='cont-input mBottom'>
                    <label>Orario di chiusura</label>
                    <input
                        type='time'
                        className='input-bianco'
                        disabled={todayInfo.closed || todayInfo.opening2 === ''}
                        value={formatH24(todayInfo.closing2)}
                        min={formatH24(todayInfo.opening2)}
                        required={todayInfo.opening2 !== ''}
                        onChange={(e) => handlePropertyChange(e, 'closing2')}
                    />
                </div>
                <button
                    className='stato open'
                    onClick={handleReset}
                >
                    Reset Orari
                </button>
                <button
                    className={`stato ${todayInfo.closed ? 'closed' : 'open'}`}
                    onClick={handleClose}
                >
                    Chiuso
                  </button>
            </div>
        </div>

    )
}



export const WeeklyOpening = ({ currentPage, jsonKey, data }) => {

    const { storeInfo } = useStoreInfo();
    const { analytics } = useMetrics();

    const handleClick = () => {
        const category = storeInfo.data.customer;
        const action = `${currentPage.name}-click-details`;
        const label = `${storeInfo.data.storeCode}-${storeInfo.data.name}-${storeInfo.data.address}`;
        analytics.event(category, action, label);
    }

    const index = Object.keys(data).indexOf(jsonKey);
    const day = data[jsonKey].wd;

    return (
        <div className='tab' >
            <input type="radio" id={`rd${index}`} name="rd" onClick={handleClick} />
            <label className="tab-label" htmlFor={`rd${index}`}>{day}</label>
            <Opening
                jsonKey={jsonKey}
                data={data}
                updateStoreFunction={updateWeeklyOpeningsData}
            />
        </div>
    )
}




export const SpecialOpening = ({ currentPage, jsonKey, data }) => {
    const { storeInfo } = useStoreInfo();
    const { analytics } = useMetrics();
    const { state: { specialOpeningsData }, dispatch } = useStore();

    const handleRemove = () => {
        const newData = {
            ...data,
            [jsonKey]: {
                ...data[jsonKey],
                deleted: true
            }
        }
        console.log('deleting item...', newData);
        dispatch(updateSpecialOpeningsData(newData));
    }

    const index = Object.keys(specialOpeningsData).indexOf(jsonKey);

    const handleClick = () => {
        const category = storeInfo.data.customer;
        const action = `${currentPage.name}-click-details`;
        const label = `${storeInfo.data.storeCode}-${storeInfo.data.name}-${storeInfo.data.address}`;
        analytics.event(category, action, label);
    }

    return (
        <div className='tab' >
            <input type="radio" id={`rd${index}`} name="rd" onClick={handleClick} />
            <label className="tab-label" htmlFor={`rd${index}`}>
                {moment(new Date(jsonKey)).format('DD-MM-YYYY')}
                <a href='#' className='trash' onClick={handleRemove}>
                    <i className='fa fa-trash' aria-hidden={true} />
                </a>
            </label>
            <Opening
                jsonKey={jsonKey}
                data={specialOpeningsData}
                updateStoreFunction={updateSpecialOpeningsData}
            />
        </div>
    )
}

